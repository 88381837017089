// **api server

// export const API_HOST = '128.199.226.2';
// export const WS_HOST = '128.199.226.2';
// export const API_URL = `http://${API_HOST}/`;
// export const IMG_URL = `http://${API_HOST}/storage/`;
// export const FILE_URL = `http://${API_HOST}/storage/`;

export const API_HOST = "api.humoyundjan.uz";
export const WS_HOST = "api.humoyundjan.uz";
export const API_URL = `https://${API_HOST}/`;
export const IMG_URL = `https://${API_HOST}/storage/`;
export const FILE_URL = `https://${API_HOST}/storage/`;
